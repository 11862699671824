import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import ImagesData from "../../components/images"

const SolarSamaiPage = () => (
  <Layout>
    <SEO title="Solar Samai - (White metal)" />
    <div className="container">
      <div className="row">
        <div className="col-xs-12 col-md-12 flex-center justify-center position-ref font-weight-bold">
          <h3 className="agney-rainbow"><b>Solar Samai - (White metal)</b></h3>
        </div>
      </div> <br/>
      <div className="row">
        <div className="col-xs-2 col-md-2">
          <ImagesData imgName="samai_1" className="img-thumbnail img-responsive w-100 center-block" width="100%" alt="Agneya Products" title="Agneya Special Solar Products"/>
        </div>
        <div className="col-xs-10 col-md-10">
          <ol>
            <li><b className="font-weight-bold">Specifications</b> :
              <ul>
                <li>Samai material - White Metal</li>
                <li>Height - 23cm (approx)</li>
                <li>Base Acrylic Material (10cm*10cm*3.5cm)</li>
                <li>Count of Led lights - 5</li>
                <li>Solar charging : Min 6-8 hrs (good sunlight)</li>
              </ul>
            </li>
            <li>
<b className="font-weight-bold">Utilization</b> : 10 - 12 Hrs. (fully charged)
            </li>
            <li>
<b className="font-weight-bold">Additional feature</b> : It has USB facility 
(can be charged with mobile charger incase of low sunlight)
            </li>
            <li>
<b className="font-weight-bold">MRP</b> : ₹ 2000/-
            </li>
          </ol>
        </div>
      </div> <br/>
      <div className="row font-weight-bold">
        <div className="col-xs-12 col-md-12">
          <h4><b>User Guide</b></h4>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 col-md-12">
The box will act as a solar charger of your Samai. <b className="font-weight-bold">Before first use, keep the box for charging in the sunlight for atleast one day. </b>

For charging keep the box bit tilted (facing solar panel towards sunlight) at place where it can get maximum sunlight during the day.

Once its charged, then match the two signs on the box with the signs on the bottom of the Samai & press it. And its done. Your Samai is lighten up and ready to use.

To switch off the Samai, rotate it in the direction of the arrow.
        </div>
      </div>
      <br/>
      <div className="row font-weight-bold">
        <div className="col-xs-12 col-md-12">
          <h4><b>Precautions</b></h4>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 col-md-12">
Every morning, detach the box from Samai and keep it for charging. A simple pull will separate the Samai from the box.
<b className="font-weight-bold"> Please be careful that the buttons on the box are not touching any metal surface while charging. </b>

If Samai gets deem, detach it from the base. Please don’t use the Samai till box gets charged sufficiently.

If you find that there is low sunlight, don't worry. Keep it for charging. It will still get charged but it will require more time than usual.

If Samai is not in use, then it should  be kept separate from the box. Don’t keep the box in cupboard. Keep the box in the light (facing solar panel towards sunlight). It will prevent self discharging.

If there is no sunlight, mobile charger can be used for charging. It is SOLAR SAMAI, so please use mobile charger only in the absence of sunlight. Overcharging through mobile charger, may damage the battery. If any doubt, feel free to contact.
        </div>
      </div> <br/>
    </div>
  </Layout>
)

export default SolarSamaiPage
